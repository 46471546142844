import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'

export function getToken () {
  return Cookies.get(TokenKey)
}

export function setToken (token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken () {
  return Cookies.remove(TokenKey)
}

export function setStationId(stationId){
  return Cookies.set("stationId",stationId)
}

export function getStationId(){
  return Cookies.get("stationId")
}

export function setOpenid(openid){
  return Cookies.set("openid", openid)
}

export function getOpenId(){
  return Cookies.get("openid")
}

export function setUserId(id){
  return Cookies.set("userId", id)
}

export function getUserId(){
  return Cookies.get("userId")
}